<template lang="pug">
include ../../../configs/template
div.row.tex-left
  div.col-sm-12.col-md-6.text-left
    +data-info('crewingCompanyName','statement.company_name')
  div.col-sm-12.col-md-6.text-left
    +data-info('fullName','statement.director_full_name')
  div.col-sm-12.col-md-6.text-left
    +data-info('edrpou','statement.edrpou')
  div.col-sm-12.col-md-6.text-left
    +data-info('address','statement.address')
  div.col-sm-12.col-md-6.text-left
    +data-info('email','statement.email')
  div.col-sm-12.col-md-6.text-left
    +data-info('phoneNumber','statement.phone')
  div.col-sm-12.col-md-6.text-left
    +data-info('createDate','statement.created_at')
  div.col-sm-12.col-md-6.text-left
    +data-info-status('status_document', 'getDirectoryObject({ id: statement.status_document, value: "statuses"})[nameLang]', 'getStatus(statement.status_document)')
</template>

<script>
import { getStatus } from '@/mixins/main'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'CrewingStatementInfo',
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      getStatus
    }
  },
  computed: {
    ...mapGetters(['getDirectoryObject']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    })
  }
}
</script>
